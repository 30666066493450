:root {
	font-size: 16px;
}

body {
	margin: 0;
	font-family: 'Cabinet Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #efefef;
}

* {
	box-sizing: border-box;
}
